<button
  data-intercom-target="Main header webshop selector"
  aria-label="Main header webshop selector"
  class="webshop-dropdown-button"
  appOverlay
  #overlay="overlay">
  <mat-icon svgIcon="shopping-basket" class="toolbar-icon"></mat-icon>

  @if (webshop(); as webshop) {
    <span>{{ webshop.name }}</span>
  }

  <mat-icon svgIcon="angle-down-xs" class="toolbar-icon"></mat-icon>

  <ng-template cdk-portal>
    <app-overlay-container-v2>
      <app-basic-filter
        i18n-placeholder="filter webshop placeholder"
        placeholder="Filter webshops"
        (filteredText)="onWebshopFilter($event)"></app-basic-filter>

      <app-webshop-data-table
        [dataSource]="dataSource$"
        [selectedWebshop]="webshop()"
        [filter]="filterSubject$"
        (selectedWebshopChanged)="
          selectedWebshop($event); overlay._closeOverlay()
        ">
      </app-webshop-data-table>
    </app-overlay-container-v2>
  </ng-template>
</button>
