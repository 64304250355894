import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { WebshopMetrics as WebshopMetricsV1 } from '../model/webshop-metrics.model';
import { WebshopMetrics } from 'src/app/shared/models/metrics/v1/webshop-metrics.model';

export class WebshopMetricsAdapter
  implements DataAdapter<WebshopMetricsV1, WebshopMetrics>
{
  transform(data: WebshopMetricsV1): WebshopMetrics {
    return {
      date: data.date,
      serviceLevel: data.serviceLevel,
      inventoryValue: data.inventoryValue,
      turnoverTime: data.turnoverTime,
      deadstockValue: data.deadstockValue,
      inFullLevel: data.inFullLevel ? data.inFullLevel * 100 : null,
      onTimeLevel: data.onTimeLevel ? data.onTimeLevel * 100 : null,
    };
  }
}
