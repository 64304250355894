<div class="general-info-container">
  <app-info-card
    data-intercom-target="cfa4c472-5e26-4c5f-a271-6fe509d9077f"
    aria-label="Dashboard inventory value card"
    class="info-card"
    i18n-cardTitle="dashboard general info inventory value"
    cardTitle="Inventory value">
    <ng-container
      *ngIf="inventoryValue$ | async as inventory; else cacheUnavailable">
      <div appCurrencyStyle>
        {{ inventory | customCurrency }}
      </div>
    </ng-container>
  </app-info-card>

  <app-info-card
    data-intercom-target="Metrics deadstock value card"
    aria-label="Metrics deadstock value card"
    class="info-card"
    [cardTitle]="DEADSTOCK_STRING">
    <ng-container
      *ngIf="deadStockValue$ | async as deadStockValue; else cacheUnavailable">
      <div appCurrencyStyle>
        {{ deadStockValue | customCurrency }}
      </div>
    </ng-container>
  </app-info-card>

  <app-info-card
    data-intercom-target="001eac3b-b441-43ab-a036-f2bac3bcca74"
    aria-label="Dashboard trend card"
    class="info-card"
    i18n-cardTitle="dashboard general info trend"
    cardTitle="Trend">
    <ng-container
      *ngIf="trendValue$ | async as trendValue; else cacheUnavailable">
      <span [ngStyle]="{ color: trendColor$ | async }"
        >{{ trendValue | customDecimal
        }}<span class="gray"><small> %</small></span></span
      >
    </ng-container>
  </app-info-card>

  <app-info-card
    data-intercom-target="281cdcdd-24ff-4709-abf6-5a8d7b0c9041"
    aria-label="Dashboard seasonality factor card"
    class="info-card"
    i18n-cardTitle="dashboard general info seasonality factor"
    cardTitle="Seasonality factor">
    <ng-container
      *ngIf="(seasonalFactor$ | async) !== null; else cacheUnavailable">
      <span [ngStyle]="{ color: seasonalFactorColor$ | async }"
        >{{ seasonalFactor$ | async | customDecimal
        }}<span class="gray"><small> %</small></span></span
      >
    </ng-container>
  </app-info-card>
</div>

<ng-template #cacheUnavailable>{{ NOT_AVAILABLE }}</ng-template>
