import { Injectable, inject } from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';
import {
  LoadWebshopMetrics,
  ResetWebshopMetrics,
} from '../actions/webshop-metrics.actions';
import { WebshopMetricsService } from 'src/app/core/api/metrics/v1/webshop-metrics.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { catchError, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';

export interface WebshopMetricsStateModel {
  loading: boolean;
  date: string | null;
  serviceLevel: number;
  inventoryValue: number;
  turnoverTime: number;
  deadstockValue: number;
  inFullLevel: number | null;
  onTimeLevel: number | null;
}

@State<WebshopMetricsStateModel>({
  name: 'webshopMetricsState',
  defaults: {
    loading: true,
    date: null,
    serviceLevel: 0,
    inventoryValue: 0,
    turnoverTime: 0,
    deadstockValue: 0,
    inFullLevel: null,
    onTimeLevel: null,
  },
})
@Injectable()
export class WebshopMetricsState {
  private webshopMetricsService = inject(WebshopMetricsService);

  private store = inject(Store);

  @Action(LoadWebshopMetrics)
  loadWebshopMetrics(ctx: StateContext<WebshopMetricsStateModel>) {
    ctx.setState(
      patch({
        loading: true,
      })
    );

    return this._fetchWebshopMetrics(ctx).pipe(
      tap(metrics => {
        ctx.setState(
          patch({
            loading: false,
            date: metrics.date,
            serviceLevel: metrics.serviceLevel,
            inventoryValue: metrics.inventoryValue,
            turnoverTime: metrics.turnoverTime,
            deadstockValue: metrics.deadstockValue,
            inFullLevel: metrics.inFullLevel,
            onTimeLevel: metrics.onTimeLevel,
          })
        );
      })
    );
  }

  @Action(ResetWebshopMetrics)
  resetWebshopMetrics(ctx: StateContext<WebshopMetricsStateModel>) {
    ctx.setState(
      patch({
        loading: true,
        date: null,
        serviceLevel: 0,
        inventoryValue: 0,
        turnoverTime: 0,
        deadstockValue: 0,
        inFullLevel: null,
        onTimeLevel: null,
      })
    );
  }

  private _fetchWebshopMetrics(ctx: StateContext<WebshopMetricsStateModel>) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.webshopMetricsService.fetchWebshopMetrics(webshopUuid).pipe(
      catchError(e => {
        ctx.setState(
          patch({
            loading: false,
            date: null,
            serviceLevel: 0,
            inventoryValue: 0,
            turnoverTime: 0,
            deadstockValue: 0,
            inFullLevel: null,
            onTimeLevel: null,
          })
        );

        throw new Error(e.message || e);
      })
    );
  }
}
