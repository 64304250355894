import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  Actions,
  Select,
  Store,
  ofActionSuccessful,
  select,
} from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { WebshopChanged } from 'src/app/core/actions/webshop.action';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { WebshopRowClicked } from 'src/app/shared/components/webshop-data-table/actions/webshop-table.actions';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';

@Component({
  selector: 'app-webshop-selector-v2',
  templateUrl: './webshop-selector-v2.component.html',
  styleUrl: './webshop-selector-v2.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebshopSelectorV2Component implements OnInit, OnDestroy {
  webshop = select<Webshop>(WebshopState.selected);

  @Select(WebshopState.webshops)
  dataSource$: Observable<Webshop[]>;

  filterSubject$: Subject<string> = new BehaviorSubject('');

  private store = inject(Store);

  private actions = inject(Actions);

  ngOnInit(): void {
    this.actions.pipe(ofActionSuccessful(WebshopRowClicked));
  }

  selectedWebshop(webshop: Webshop) {
    this.store.dispatch(new WebshopChanged(webshop));
  }

  onWebshopFilter(filter: string) {
    this.filterSubject$.next(filter);
  }

  ngOnDestroy(): void {
    this.filterSubject$.complete();
  }
}
