import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  exports: [MatIconModule],
  imports: [CommonModule, MatIconModule],
  providers: [MatIconRegistry, provideHttpClient(withInterceptorsFromDi())],
})
export class CustomIconRegistryModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry
      .addSvgIcon(
        'angle-down-xs',
        this.setPath('assets/icons/angle-down-xs.svg')
      )
      .addSvgIcon('angle-down', this.setPath('assets/icons/angle-down.svg'))
      .addSvgIcon(
        'angle-left-xs',
        this.setPath('assets/icons/angle-left-xs.svg')
      )
      .addSvgIcon('angle-left', this.setPath('assets/icons/angle-left.svg'))
      .addSvgIcon(
        'angle-right-xs',
        this.setPath('assets/icons/angle-right-xs.svg')
      )
      .addSvgIcon('angle-right', this.setPath('assets/icons/angle-right.svg'))
      .addSvgIcon('angle-up-xs', this.setPath('assets/icons/angle-up-xs.svg'))
      .addSvgIcon('angle-up', this.setPath('assets/icons/angle-up.svg'))
      .addSvgIcon(
        'arrow-decrease-down-crossed',
        this.setPath('assets/icons/arrow-decrease-down-crossed.svg')
      )
      .addSvgIcon(
        'arrow-decrease-down',
        this.setPath('assets/icons/arrow-decrease-down.svg')
      )
      .addSvgIcon(
        'arrow-dots-rotate-right',
        this.setPath('assets/icons/arrow-dots-rotate-right.svg')
      )
      .addSvgIcon(
        'arrow-down-xs',
        this.setPath('assets/icons/arrow-down-xs.svg')
      )
      .addSvgIcon('arrow-down', this.setPath('assets/icons/arrow-down.svg'))
      .addSvgIcon(
        'arrow-upload',
        this.setPath('assets/icons/arrow-download.svg')
      )
      .addSvgIcon(
        'arrow-increase-rounded',
        this.setPath('assets/icons/arrow-increase-rounded.svg')
      )
      .addSvgIcon(
        'arrow-left-xs',
        this.setPath('assets/icons/arrow-left-xs.svg')
      )
      .addSvgIcon('arrow-left', this.setPath('assets/icons/arrow-left.svg'))
      .addSvgIcon('arrow-log-in', this.setPath('assets/icons/arrow-log-in.svg'))
      .addSvgIcon(
        'arrow-log-out',
        this.setPath('assets/icons/arrow-log-out.svg')
      )
      .addSvgIcon(
        'arrow-right-xs',
        this.setPath('assets/icons/arrow-right-xs.svg')
      )
      .addSvgIcon('arrow-right', this.setPath('assets/icons/arrow-right.svg'))
      .addSvgIcon(
        'arrow-u-left-down',
        this.setPath('assets/icons/arrow-u-left-down.svg')
      )
      .addSvgIcon(
        'arrow-u-right-down',
        this.setPath('assets/icons/arrow-u-right-down.svg')
      )
      .addSvgIcon(
        'arrow-up-down',
        this.setPath('assets/icons/arrow-up-down.svg')
      )
      .addSvgIcon('arrow-up-xs', this.setPath('assets/icons/arrow-up-xs.svg'))
      .addSvgIcon('arrow-up', this.setPath('assets/icons/arrow-up.svg'))
      .addSvgIcon(
        'arrow-download',
        this.setPath('assets/icons/arrow-upload.svg')
      )
      .addSvgIcon('back', this.setPath('assets/icons/back.svg'))
      .addSvgIcon(
        'bar-chart-magnifying-glass',
        this.setPath('assets/icons/bar-chart-magnifying-glass.svg')
      )
      .addSvgIcon('bell', this.setPath('assets/icons/bell.svg'))
      .addSvgIcon(
        'bookmark-star',
        this.setPath('assets/icons/bookmark-star.svg')
      )
      .addSvgIcon('box-closed', this.setPath('assets/icons/box-closed.svg'))
      .addSvgIcon('box-open', this.setPath('assets/icons/box-open.svg'))
      .addSvgIcon('calendar', this.setPath('assets/icons/calendar.svg'))
      .addSvgIcon('chat-bubble', this.setPath('assets/icons/chat-bubble.svg'))
      .addSvgIcon(
        'chat-feedback',
        this.setPath('assets/icons/chat-feedback.svg')
      )
      .addSvgIcon('chat-support', this.setPath('assets/icons/chat-support.svg'))
      .addSvgIcon(
        'check-rounded-filled-xs',
        this.setPath('assets/icons/check-rounded-filled-xs.svg')
      )
      .addSvgIcon('check-xs', this.setPath('assets/icons/check-xs.svg'))
      .addSvgIcon('check', this.setPath('assets/icons/check.svg'))
      .addSvgIcon(
        'clipboard-check',
        this.setPath('assets/icons/clipboard-check.svg')
      )
      .addSvgIcon(
        'clipboard-lines',
        this.setPath('assets/icons/clipboard-lines.svg')
      )
      .addSvgIcon(
        'clipboard-notes',
        this.setPath('assets/icons/clipboard-notes.svg')
      )
      .addSvgIcon(
        'clock-arrow-left',
        this.setPath('assets/icons/clock-arrow-left.svg')
      )
      .addSvgIcon(
        'clock-arrow-right',
        this.setPath('assets/icons/clock-arrow-right.svg')
      )
      .addSvgIcon('clock', this.setPath('assets/icons/clock.svg'))
      .addSvgIcon('collapse', this.setPath('assets/icons/collapse.svg'))
      .addSvgIcon('copy', this.setPath('assets/icons/copy.svg'))
      .addSvgIcon('cross-xs', this.setPath('assets/icons/cross-xs.svg'))
      .addSvgIcon('cross', this.setPath('assets/icons/cross.svg'))
      .addSvgIcon(
        'curve-arrow-left',
        this.setPath('assets/icons/curve-arrow-left.svg')
      )
      .addSvgIcon(
        'curve-arrow-right',
        this.setPath('assets/icons/curve-arrow-right.svg')
      )
      .addSvgIcon('dots-cross', this.setPath('assets/icons/dots-cross.svg'))
      .addSvgIcon(
        'dots-horizontal-xs',
        this.setPath('assets/icons/dots-horizontal-xs.svg')
      )
      .addSvgIcon(
        'dots-horizontal',
        this.setPath('assets/icons/dots-horizontal.svg')
      )
      .addSvgIcon(
        'dots-vertical-xs',
        this.setPath('assets/icons/dots-vertical-xs.svg')
      )
      .addSvgIcon(
        'dots-vertical',
        this.setPath('assets/icons/dots-vertical.svg')
      )
      .addSvgIcon('duplicate', this.setPath('assets/icons/duplicate.svg'))
      .addSvgIcon('expand', this.setPath('assets/icons/expand.svg'))
      .addSvgIcon(
        'external-link',
        this.setPath('assets/icons/external-link.svg')
      )
      .addSvgIcon(
        'external-link-xs',
        this.setPath('assets/icons/external-link-xs.svg')
      )
      .addSvgIcon('eye-closed', this.setPath('assets/icons/eye-closed.svg'))
      .addSvgIcon('eye-open', this.setPath('assets/icons/eye-open.svg'))
      .addSvgIcon(
        'filter-filled-xs',
        this.setPath('assets/icons/filter-filled-xs.svg')
      )
      .addSvgIcon(
        'filter-filled',
        this.setPath('assets/icons/filter-filled.svg')
      )
      .addSvgIcon(
        'filter-outline',
        this.setPath('assets/icons/filter-outline.svg')
      )
      .addSvgIcon('flag-en', this.setPath('assets/icons/flag-en.svg'))
      .addSvgIcon('flag-nl', this.setPath('assets/icons/flag-nl.svg'))
      .addSvgIcon('flag-us', this.setPath('assets/icons/flag-us.svg'))
      .addSvgIcon('forward', this.setPath('assets/icons/forward.svg'))
      .addSvgIcon('full-screen', this.setPath('assets/icons/full-screen.svg'))
      .addSvgIcon('home', this.setPath('assets/icons/home.svg'))
      .addSvgIcon(
        'info-rounded-filled-xs',
        this.setPath('assets/icons/info-rounded-filled-xs.svg')
      )
      .addSvgIcon(
        'info-rounded-xs',
        this.setPath('assets/icons/info-rounded-xs.svg')
      )
      .addSvgIcon('info-rounded', this.setPath('assets/icons/info-rounded.svg'))
      .addSvgIcon(
        'list-vertical',
        this.setPath('assets/icons/list-vertical.svg')
      )
      .addSvgIcon(
        'magnifying-glass',
        this.setPath('assets/icons/magnifying-glass.svg')
      )
      .addSvgIcon('megaphone', this.setPath('assets/icons/megaphone.svg'))
      .addSvgIcon('minimize', this.setPath('assets/icons/minimize.svg'))
      .addSvgIcon('minus-xs', this.setPath('assets/icons/minus-xs.svg'))
      .addSvgIcon('minus', this.setPath('assets/icons/minus.svg'))
      .addSvgIcon('moon', this.setPath('assets/icons/moon.svg'))
      .addSvgIcon('not-allowed', this.setPath('assets/icons/not-allowed.svg'))
      .addSvgIcon('pencil-edit', this.setPath('assets/icons/pencil-edit.svg'))
      .addSvgIcon(
        'pencil-edit-xs',
        this.setPath('assets/icons/pencil-edit-xs.svg')
      )
      .addSvgIcon('plus-xs', this.setPath('assets/icons/plus-xs.svg'))
      .addSvgIcon('plus', this.setPath('assets/icons/plus.svg'))
      .addSvgIcon(
        'promotion-tag',
        this.setPath('assets/icons/promotion-tag.svg')
      )
      .addSvgIcon('puzzle', this.setPath('assets/icons/puzzle.svg'))
      .addSvgIcon(
        'questionmark-rounded',
        this.setPath('assets/icons/questionmark-rounded.svg')
      )
      .addSvgIcon('rotate-left', this.setPath('assets/icons/rotate-left.svg'))
      .addSvgIcon('rotate-right', this.setPath('assets/icons/rotate-right.svg'))
      .addSvgIcon(
        'rotating-minus',
        this.setPath('assets/icons/rotating-minus.svg')
      )
      .addSvgIcon('rotating', this.setPath('assets/icons/rotating.svg'))
      .addSvgIcon(
        'rotating-slash',
        this.setPath('assets/icons/rotating-slash.svg')
      )
      .addSvgIcon('rotating-xs', this.setPath('assets/icons/rotating-xs.svg'))
      .addSvgIcon(
        'rotating-slash-xs',
        this.setPath('assets/icons/rotating-slash-xs.svg')
      )
      .addSvgIcon('settings-cog', this.setPath('assets/icons/settings-cog.svg'))
      .addSvgIcon(
        'shopping-basket',
        this.setPath('assets/icons/shopping-basket.svg')
      )
      .addSvgIcon(
        'store-warning',
        this.setPath('assets/icons/store-warning.svg')
      )
      .addSvgIcon('store', this.setPath('assets/icons/store.svg'))
      .addSvgIcon('table', this.setPath('assets/icons/table.svg'))
      .addSvgIcon('trash', this.setPath('assets/icons/trash.svg'))
      .addSvgIcon('truck', this.setPath('assets/icons/truck.svg'))
      .addSvgIcon(
        'user-filled-lg',
        this.setPath('assets/icons/user-filled-lg.svg')
      )
      .addSvgIcon('user-filled', this.setPath('assets/icons/user-filled.svg'))
      .addSvgIcon('user-rounded', this.setPath('assets/icons/user-rounded.svg'))
      .addSvgIcon(
        'verified-badge',
        this.setPath('assets/icons/verified-badge.svg')
      )
      .addSvgIcon(
        'verified-filled-xs',
        this.setPath('assets/icons/verified-filled-xs.svg')
      )
      .addSvgIcon(
        'warning-rounded-filled-xs',
        this.setPath('assets/icons/warning-rounded-filled-xs.svg')
      )
      .addSvgIcon(
        'warning-rounded-xs',
        this.setPath('assets/icons/warning-rounded-xs.svg')
      )
      .addSvgIcon(
        'warning-rounded',
        this.setPath('assets/icons/warning-rounded.svg')
      )
      .addSvgIcon(
        'warning-triangle-filled-xs',
        this.setPath('assets/icons/warning-triangle-filled-xs.svg')
      )
      .addSvgIcon(
        'warning-triangle',
        this.setPath('assets/icons/warning-triangle.svg')
      )
      .addSvgIcon('boxes', this.setPath('assets/icons/boxes.svg'))
      .addSvgIcon('chain', this.setPath('assets/icons/chain.svg'))
      .addSvgIcon('chain-xs', this.setPath('assets/icons/chain-xs.svg'))
      .addSvgIcon(
        'dock-to-right',
        this.setPath('assets/icons/dock-to-right.svg')
      )
      .addSvgIcon('dock-to-left', this.setPath('assets/icons/dock-to-left.svg'))
      .addSvgIcon(
        'dock-to-bottom',
        this.setPath('assets/icons/dock-to-bottom.svg')
      )
      .addSvgIcon('detach', this.setPath('assets/icons/detach.svg'))
      .addSvgIcon(
        'notes-notification',
        this.setPath('assets/icons/notes-notification.svg')
      )
      .addSvgIcon(
        'status-report',
        this.setPath('assets/icons/status-report.svg')
      )
      .addSvgIcon('service', this.setPath('assets/icons/service.svg'))
      .addSvgIcon('mastercard', this.setPath('assets/icons/mastercard.svg'))
      .addSvgIcon('visa', this.setPath('assets/icons/visa.svg'))
      .addSvgIcon('amex', this.setPath('assets/icons/american-express.svg'))
      .addSvgIcon('analytics', this.setPath('assets/icons/analytics.svg'))
      .addSvgIcon('check-lg', this.setPath('assets/icons/check-lg.svg'))
      .addSvgIcon('delete', this.setPath('assets/icons/delete.svg'))
      .addSvgIcon('pause', this.setPath('assets/icons/pause.svg'));
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
