import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { LoadDashboardCache } from 'src/app/core/actions/dashboard-cache.action';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import {
  GLOBAL,
  NOT_AVAILABLE_VALUE,
} from 'src/app/core/constants/global.constants';
import { DashboardCacheState } from 'src/app/core/states/dashboard-cache.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { DashboardDetails } from 'src/app/shared/models/stock-level/dashboard-cache.model';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import { WebshopMetricsStateQueries } from '../webshop-metrics/state/webshop-metrics.queries';
import { STRINGS } from '../webshop-metrics/model/webshop-metrics.strings';
import { LoadWebshopMetrics } from '../webshop-metrics/actions/webshop-metrics.actions';

/**
 * Component that displays all arround information about the webshop.
 * The information is displayed as a set of info cards that can be expanded
 * easily with more information.
 */
@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralInfoComponent implements OnInit, OnDestroy {
  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly GLOBAL = GLOBAL;

  readonly DEADSTOCK_STRING = STRINGS.metadata.deadStock;

  cache: DashboardDetails = null;
  webshop: Webshop = null;

  seasonalFactor$: Subject<number | null> = new BehaviorSubject<number | null>(
    null
  );

  seasonalFactorColor$: Subject<string> = new BehaviorSubject<string>(
    this.GLOBAL.colors.light.neutralValue
  );

  trendValue$: Subject<number | null> = new BehaviorSubject<number | null>(
    null
  );

  trendColor$: Subject<string> = new BehaviorSubject<string>(
    this.GLOBAL.colors.light.neutralValue
  );

  overallServiceLevel$: Subject<number | null> = new BehaviorSubject<
    number | null
  >(null);

  stockTurnOver$: Subject<number | null> = new BehaviorSubject<number | null>(
    null
  );

  inventoryValue$: Subject<number | null> = new BehaviorSubject<number | null>(
    null
  );

  revenue$: Subject<number | null> = new BehaviorSubject<number | null>(null);

  @Select(WebshopMetricsStateQueries.deadStockValue)
  deadStockValue$: Observable<number>;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(distinctUntilKeyChanged('uuid'), takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new LoadWebshopMetrics());
      });

    this.actions
      .pipe(ofActionDispatched(LoadDashboardCache), takeUntil(this.destroy$))
      .subscribe(() => {
        this.onCacheUnavailable();
      });

    this.actions
      .pipe(ofActionDispatched(WebshopSelected), takeUntil(this.destroy$))
      .subscribe(() => {
        this.overallServiceLevel$.next(null);
      });

    this.store
      .select(DashboardCacheState.cache)
      .pipe(takeUntil(this.destroy$))
      .subscribe((cache: DashboardDetails) => {
        this.dataUpdated(cache, this.webshop);
      });

    this.store
      .select(WebshopState.selected)
      .pipe(takeUntil(this.destroy$))
      .subscribe((webshop: Webshop | null) => {
        this.dataUpdated(this.cache, webshop);
      });
  }

  ngOnDestroy(): void {
    this.trendValue$.complete();
    this.seasonalFactor$.complete();
    this.stockTurnOver$.complete();
    this.inventoryValue$.complete();
    this.revenue$.complete();
    this.overallServiceLevel$.complete();
    this.trendColor$.complete();
    this.seasonalFactorColor$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Returns the color based on the sign of the value.
   * Green if positive, Red if negative or Neutral if it is equal
   * to zero.
   * @param value the decimal value
   */
  private percentageColor(value: number): string {
    if (value < 0) {
      return this.GLOBAL.colors.light.negativeValue;
    } else if (value > 0) {
      return this.GLOBAL.colors.light.positiveValue;
    }

    return this.GLOBAL.colors.light.neutralValue;
  }

  /**
   * Computes the overall service level value.
   */
  private computeOverallserviceLevel(
    webshop: Webshop | null,
    cache: DashboardDetails | null
  ): number {
    return (
      (webshop.margin.a * cache.categoryA.currentServiceLevel +
        webshop.margin.b * cache.categoryB.currentServiceLevel +
        webshop.margin.c * cache.categoryC.currentServiceLevel) /
      100
    );
  }

  /**
   * Updates the information on the view when data is available from the webshop
   * and/or from the cache.
   * @param cache the dashboard cache data.
   * @param webshop the current selected webshop.
   */
  private dataUpdated(
    cache: DashboardDetails | null,
    webshop: Webshop | null
  ): void {
    this.cacheUpdated(cache);

    this.webshop = webshop;

    if (webshop == null || cache === null) {
      return;
    }

    const overalServiceLevel = this.computeOverallserviceLevel(webshop, cache);
    this.overallServiceLevel$.next(overalServiceLevel);
  }

  /**
   * Updates the dashboard values with the newest cache data.
   * @param cache newest dashboard cache data
   */
  private cacheUpdated(cache: DashboardDetails | null): void {
    this.cache = cache;

    if (cache == null) {
      return;
    }

    this.seasonalFactorColor$.next(
      this.percentageColor(this.cache.seasonalFactor)
    );

    this.trendColor$.next(this.percentageColor(this.cache.trend));
    this.trendValue$.next(cache.trend);

    this.seasonalFactor$.next(cache.seasonalFactor);

    this.stockTurnOver$.next(cache.stockTurnOver);

    this.inventoryValue$.next(cache.inventoryValue);

    this.revenue$.next(cache.revenue);
  }

  /**
   * Triggered when the cache is not available, either because the endpoint
   * failed or any other reason.
   */
  private onCacheUnavailable(): void {
    this.trendValue$.next(null);
    this.seasonalFactor$.next(null);
    this.stockTurnOver$.next(null);
    this.inventoryValue$.next(null);
    this.revenue$.next(null);
  }
}
