<button
  data-intercom-target="10e9e435-bd54-460a-ba15-ede987d39f84"
  aria-label="Main header account button"
  [cdkMenuTriggerFor]="optionsMenu"
  appTooltip="Account"
  i18n-appTooltip="toolbar account button"
  appButton
  appButtonIcon
  variant="link"
  transparent
  class="toolbar-button">
  <mat-icon
    svgIcon="user-filled-lg"
    class="toolbar-icon-major account-icon"></mat-icon>
</button>

<ng-template #optionsMenu>
  <app-menu>
    @if (user$ | async; as user) {
      <div class="account-info">
        <p class="account-info-name">{{ user.name }}</p>
        <p
          class="account-info-email"
          [appTooltip]="user.email"
          tooltipClass="extended-tooltip"
          [tooltipShowDelay]="300"
          tooltipShouldCheckOverflow>
          {{ user.email }}
        </p>
      </div>

      <div class="divider"></div>
    }

    <button
      data-intercom-target="5425248c-b8a7-4dcc-adde-7f5b02fe3399"
      aria-label="Main header account User preferences"
      appMenuItem
      (click)="onManageAccountClick()"
      *ngIf="(user$ | async).changePassword">
      <mat-icon svgIcon="user-filled" class="menu-icon"></mat-icon>
      <span i18n="user settings menu option">User settings</span>
    </button>

    <div
      class="darkmode-container"
      data-intercom-target="94016dde-0e34-4319-a138-d6631bf3d430"
      aria-label="Main header account Dark mode">
      <div class="darkmode-label">
        <mat-icon svgIcon="moon" class="menu-icon"></mat-icon>
        <span
          i18n="account settings menu option darkmode"
          class="darkmode-label-text"
          >Dark mode</span
        >
      </div>

      <span (click)="$event.stopPropagation()">
        <app-toggle
          [ngModel]="darkModeEnabled$ | async"
          (ngModelChange)="setColorTheme($event)"
          [showState]="false">
        </app-toggle>
      </span>
    </div>

    <div class="divider"></div>

    @if (importsEnabled$ | async) {
      <button
        data-intercom-target="Main header account Imports"
        aria-label="Main header account Imports"
        appMenuItem
        (click)="onImportsClick()">
        <mat-icon svgIcon="arrow-upload" class="menu-icon"></mat-icon>
        <span>{{ STRINGS.title }}</span>
      </button>
    }

    @if (exportsEnabled$ | async) {
      <button
        data-intercom-target="ee28db20-7b7a-492b-9474-7afb2ed24f98"
        aria-label="Main header account Exports"
        appMenuItem
        (click)="onExportsClick()">
        <mat-icon svgIcon="arrow-download" class="menu-icon"></mat-icon>
        <span i18n="exports menu option">Exports</span>
      </button>
    }

    @if ((exportsEnabled$ | async) || (importsEnabled$ | async)) {
      <div class="divider"></div>
    }

    <button
      data-intercom-target="5b24b3cf-3a60-4b18-a9cb-077630888086"
      aria-label="Main header account Log Out"
      appMenuItem
      (click)="onLogoutClick()">
      <mat-icon svgIcon="arrow-log-out" class="menu-icon"></mat-icon>
      <span i18n="logout menu option">Log out</span>
    </button>
  </app-menu>
</ng-template>
