import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/core/modules/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { ProgressSpinnerModule } from 'src/app/shared/components/design-system/progress-spinner/progress-spinner.module';
import { NgxsModule } from '@ngxs/store';
import { TodoListV2Component } from './components/todo-list-v2/todo-list-v2.component';
import { TodoWrapperComponent } from './components/todo-list-v2/components/todo-wrapper/todo-wrapper.component';
import { TodoIconDirective } from './components/todo-list-v2/directives/todo-icon.directive';
import { TodoLabelDirective } from './components/todo-list-v2/directives/todo-label.directive';
import { PurchasesTodoComponent } from './components/todo-list-v2/components/purchases-todo/purchases-todo.component';
import { ButtonModule } from 'src/app/shared/components/design-system/button/button.module';
import { DeliveriesTodoComponent } from './components/todo-list-v2/components/deliveries-todo/deliveries-todo.component';
import { ProductsTodoComponent } from './components/todo-list-v2/components/products-todo/products-todo.component';
import { SuppliersTodoComponent } from './components/todo-list-v2/components/suppliers-todo/suppliers-todo.component';
import { TodoDataTableComponent } from './components/todo-list-v2/components/todo-data-table/todo-data-table.component';
import { SkeletonModule } from 'src/app/shared/components/design-system/skeleton/skeleton.module';
import { TodoLoadingComponent } from './components/todo-list-v2/components/todo-loading/todo-loading.component';
import { TodoNoIssuesComponent } from './components/todo-list-v2/components/todo-no-issues/todo-no-issues.component';
import { TodoIssueStatusDirective } from './components/todo-list-v2/directives/todo-issue.directive';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { DashboardCacheState } from 'src/app/core/states/dashboard-cache.state';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';
import { CustomDecimalPipe } from 'src/app/shared/pipes/custom-decimal.pipe';
import { TodoIssueIconComponent } from './components/todo-list-v2/components/todo-issue-icon/todo-issue-icon.component';
import { TodoListV2State } from './components/todo-list-v2/states/todo-list-v2.state';
import { ProductsV2State } from '../products-v2/state/products-v2.state';
import { ProposedOrdersState } from '../purchase-v3/components/proposed-orders/state/proposed-orders.state';
import { OverdueOrdersState } from '../deliveries-v2/components/overdue-orders/state/overdue-orders.state';
import { SuppliersV2State } from '../suppliers-v2/state/suppliers-v2.state';
import { WebshopMetricsComponent } from './components/webshop-metrics/webshop-metrics.component';
import { WebshopMetricsState } from './components/webshop-metrics/state/webshop-metrics.state';
import { DeliveriesV2State } from '../deliveries-v2/state/deliveries-v2.state';
import { ExpectedOrdersState } from '../deliveries-v2/components/expected-orders/state/expected-orders.state';
import { TooltipModule } from 'src/app/shared/components/design-system/tooltip/tooltip.module';

@NgModule({
  declarations: [
    DashboardComponent,
    GeneralInfoComponent,
    InfoCardComponent,
    TodoListV2Component,
    TodoWrapperComponent,
    TodoIconDirective,
    TodoLabelDirective,
    PurchasesTodoComponent,
    DeliveriesTodoComponent,
    ProductsTodoComponent,
    SuppliersTodoComponent,
    TodoDataTableComponent,
    TodoLoadingComponent,
    TodoNoIssuesComponent,
    TodoIssueStatusDirective,
    TodoIssueIconComponent,
    WebshopMetricsComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    ProgressSpinnerModule,
    NgxsModule.forFeature([
      DashboardCacheState,
      WebshopMetricsState,
      TodoListV2State,
      ProposedOrdersState,
      DeliveriesV2State,
      OverdueOrdersState,
      ExpectedOrdersState,
      ProductsV2State,
      SuppliersV2State,
    ]),
    ButtonModule,
    SkeletonModule,
    TooltipModule,
  ],
  exports: [GeneralInfoComponent],
  providers: [DecimalPipe, CustomCurrencyPipe, CustomDecimalPipe],
})
export class DashboardModule {}
