import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { STRINGS } from './model/webshop-metrics.strings';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { select, Select, Store } from '@ngxs/store';
import { WebshopMetricsStateQueries } from './state/webshop-metrics.queries';
import { Observable, distinctUntilKeyChanged } from 'rxjs';
import { WebshopMetrics } from 'src/app/shared/models/metrics/v1/webshop-metrics.model';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  LoadWebshopMetrics,
  ResetWebshopMetrics,
} from './actions/webshop-metrics.actions';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-webshop-metrics',
  templateUrl: './webshop-metrics.component.html',
  styleUrl: './webshop-metrics.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebshopMetricsComponent implements OnInit, OnDestroy {
  loading = select(WebshopMetricsStateQueries.loading);

  metrics = select<WebshopMetrics>(WebshopMetricsStateQueries.metrics);

  readonly METRICS_STRINGS = STRINGS;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly COMMON_STRINGS = MESSAGES.common;

  private store = inject(Store);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(
        distinctUntilKeyChanged('uuid'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.store.dispatch(new LoadWebshopMetrics());
      });
  }

  openCurrentServiceLevelHelpArticle(): void {
    window.open(
      'https://help.optiply.com/en/articles/6603800-service-levels',
      '_blank',
      'noopener'
    );
  }

  openInventoryTurnoverTimeHelpArticle(): void {
    window.open(
      'https://help.optiply.com/en/articles/6603815-turnover-time',
      '_blank',
      'noopener'
    );
  }

  openPurchaseAutomationHelpArticle(): void {
    window.open(
      'https://help.optiply.com/en/articles/3583395-this-is-what-optiply-is-going-to-help-you-with',
      '_blank',
      'noopener'
    );
  }

  openOnTimePurchasingHelpArticle(): void {
    window.open(
      'https://help.optiply.com/en/articles/6561077-why-is-it-important-to-stick-to-the-agenda',
      '_blank',
      'noopener'
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetWebshopMetrics());
  }
}
