export const STRINGS = {
  metadata: {
    serviceLevel: $localize`Current service level`,
    inventoryValue: $localize`Inventory value`,
    turnoverTime: $localize`Inventory turnover time`,
    deadStock: $localize`Deadstock value`,
    inFullLevel: $localize`Purchase automation`,
    onTimeLevel: $localize`On-Time purchasing `,
  },
  tooltips: {
    currentServiceLevel: $localize`Average product availability across the entire assortment. A higher percentage reduces lost sales but increases purchasing costs`,
    inventoryTurnoverTime: $localize`The number of days required to cycle through the entire inventory, indicating how quickly stock is sold and replaced`,
    purchaseAutomation: $localize`How we you are following Optiply's advised quantities. The more edits you make, the lower the percentage will be`,
    onTimePurchasing: $localize`How well you are following the Optiply's agenda. The higher you deviate from the proposed date, the lower the percentage will be`,
  },
};
