<app-datatable-container
  [loading]="false"
  class="service-level-info-container"
  withoutPagination>
  <table cdk-table [dataSource]="dataSource$ | async">
    <!-- Name Column -->
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef>
        <app-product-category
          [category]="null"
          [disabled]="true"></app-product-category>
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <app-product-category [category]="element.name"></app-product-category>
      </td>
    </ng-container>

    <!-- Desired Service Level Column -->
    <ng-container cdkColumnDef="desiredServiceLevel">
      <th
        data-intercom-target="f3e2b412-4b1a-4cee-87b1-6c20c155b3af"
        aria-label="Dashboard table desired service level"
        cdk-header-cell
        *cdkHeaderCellDef
        i18n="dashboard service level info desired service level percentage"
        class="align-right">
        Desired Service Level
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container
          *ngIf="element.desiredServiceLevel !== null; else notAvailable">
          {{ element.desiredServiceLevel | customDecimal: '1.1-1' }}
          <span class="gray">%</span>
        </ng-container>
        <ng-template #notAvailable>
          <span class="gray">{{ NOT_AVAILABLE }}</span>
        </ng-template>
      </td>
    </ng-container>

    <!-- Current Service Level Column -->
    <ng-container cdkColumnDef="currentServiceLevel">
      <th
        data-intercom-target="ac7f4157-430f-465e-9bad-69fa56e22bf5"
        aria-label="Dashboard table service level"
        cdk-header-cell
        *cdkHeaderCellDef
        i18n="dashboard service level info service level percentage"
        class="align-right">
        Service level
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container
          *ngIf="element.currentServiceLevel !== null; else notAvailable">
          {{ element.currentServiceLevel | customDecimal: '1.1-1' }}
          <span class="gray">%</span>
        </ng-container>
        <ng-template #notAvailable>
          <span class="gray">{{ NOT_AVAILABLE }}</span>
        </ng-template>
      </td>
    </ng-container>

    <!-- Inventory Value Column -->
    <ng-container cdkColumnDef="inventoryValue">
      <th
        data-intercom-target="8de3d174-7172-4f53-9b47-d8493f816084"
        aria-label="Dashboard table inventory value"
        cdk-header-cell
        *cdkHeaderCellDef
        i18n="dashboard service level info inventory value"
        class="align-right">
        Inventory value
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <div class="inventory-value-container">
          <div
            *ngIf="element?.inventoryTrendValue > 0"
            class="inventory-value-trend-container inventory-value-trend-positive">
            <span>+{{ element.inventoryTrendValue | customDecimal }}%</span>
          </div>
          <div
            *ngIf="element?.inventoryTrendValue < 0"
            class="inventory-value-trend-container inventory-value-trend-negative">
            <span> {{ element.inventoryTrendValue | customDecimal }}%</span>
          </div>
          <div
            *ngIf="element?.inventoryTrendValue === 0"
            class="inventory-value-trend-container">
            <span>{{ element.inventoryTrendValue | customDecimal }}%</span>
          </div>
          <div>
            <ng-container
              *ngIf="element.inventoryValue !== null; else notAvailable">
              <span appCurrencyStyle
                >{{ element.inventoryValue | customCurrency }}
              </span>
            </ng-container>
            <ng-template #notAvailable>
              <span class="gray">{{ NOT_AVAILABLE }}</span>
            </ng-template>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Stock TurnOver Column -->
    <ng-container cdkColumnDef="stockTurnOver">
      <th
        data-intercom-target="3954053b-a450-450d-a4cf-e43961104f80"
        aria-label="Dashboard table inventory turnover time"
        cdk-header-cell
        *cdkHeaderCellDef
        i18n="dashboard service level info inventory turnover time in days"
        class="align-right">
        Inventory Turnover Time (days)
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        {{
          element.stockTurnOver !== null && element.stockTurnOver > 365
            ? '365+'
            : element.stockTurnOver !== null
              ? (element.stockTurnOver | customDecimal)
              : NOT_AVAILABLE
        }}
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns"
      class="datatable-headers"></tr>
    <tr
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns"
      class="datatable-rows"></tr>
  </table>
</app-datatable-container>
